import { configureStore, createSlice } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import facenetReducer from '../features/auth/facenetSlice'
import userReducer from '../features/dashboard/userSlice'
import matchReducer from '../features/dashboard/matchSlice'
import detectReducer from '../features/dashboard/detectSlice'
import similarityReducer from '../features/dashboard/similaritySlice'

// Define your initial state
const initialState = {
  sidebarShow: true,
  theme: 'light',
}

// Create a slice for the state that was managed by the legacy reducer
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    set: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { set } = appSlice.actions

// Configure the store with multiple reducers and middleware
export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    auth: authReducer,
    facenet: facenetReducer,
    user: userReducer,
    match: matchReducer,
    detect: detectReducer,
    similarity: similarityReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
export default store